<template>
  <div>
    <div id="section-content" class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
        <b-input-group
          class="mb-3"
        >
          <!--<b-form-input placeholder="ค้นหา"></b-form-input>
          <b-input-group-append>
            <b-button size="sm" text="Button" variant="primary"><i class="fas fa-search"></i> ค้นหา</b-button>
          </b-input-group-append>-->
        </b-input-group>

        <b-row class="px-2">
          <b-col
           v-for="(item,index) in gameTab"
           v-bind:key="index"
           cols=6 sm=4 class="px-1 py-1"
          >
            <b-button v-if="active === item.page" block variant="primary" class="px-0" :to=item.page>{{ item.name }}</b-button>
            <b-button v-else block variant="outline-primary" class="px-0" :to=item.page>{{ item.name }}</b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col
          v-for="(item,index) in getGames.games"
          v-bind:key="index"
          cols="4" sm=3 md=2
          class="px-2"
          >
            <div class="mt-2 box-new-top img-cuesor-pointer" @click="reqStartGame(item.id, item.games_image, item.games_provider_id)" sty>
              <div class="card">
                <img class="card-img lightSweep" :src="item.games_image" alt="Card image">
                <strong class="text-center text-primary">{{ item.games_provider_id }}</strong>
                <!-- <b-button  variant="outline-primary btn-violet btn-round">เข้าเล่น</b-button> -->
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from 'axios'
import { ApiService } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
const client = new ApiService({})
export default {
  async mounted () {
    if (process.env.VUE_APP_CASINO_ENABLE.toLowerCase().trim() === 'false') {
      this.$router.push('/member')
    }
    try {
      console.log(this.$route.params.banner)
      await this.getGamesList({
        type: 3
      })
    } catch (error) {
    }
  },
  computed: {
    ...mapGetters([
      'getGames',
      'getGameUrl'
    ])
  },
  methods: {
    ...mapActions(['getGamesList', 'getGamesUrl', 'setGameDepositCreditAll']),
    goto_link () {
      window.open('https://www.google.com', '_blank')
    },
    async reqStartGame (gameId, game_img, game_name) {
      const resp = await client.post('/game/request/start', JSON.stringify({
        id: gameId
      }))
      if (resp.status_code === 'SUCCESS') {
        this.link_to_game = process.env.VUE_APP_CASINO_URL + '/' + localStorage.getItem('client_id') + '/' + resp.data._token
        this.$swal({
          text: 'คุณต้องการเข้าเล่น ' + game_name + ' ?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ใช่',
          cancelButtonText: 'ปิด',
          imageUrl: game_img,
          imageHeight: 200
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(process.env.VUE_APP_CASINO_URL + '/' + localStorage.getItem('client_id') + '/' + resp.data._token)
          }
        })
      }
    //   await axios({
    //     method: 'POST',
    //     url: this.getGames.game_setting.url,
    //     headers: { authorization: this.getGames.game_setting.authorization },
    //     data: {
    //       agentId: this.getGames.game_setting.agentId,
    //       userId: this.getGames.game_setting.userId,
    //       providerId: providerId,
    //       gameCode: code,
    //       hash: hash
    //     }
    //   }).then(async response => {
    //     const resuft = response.data
    //     if (resuft.StatusCode === '0000') {
    //       console.log('Url:', resuft.ResultValues.url)
    //       await this.setGameDepositCreditAll()
    //       window.open(resuft.ResultValues.url)
    //     }
    //   }).catch((error) => {
    //     console.log(error)
    //   })
    // },
    // async startGame (gameId) {
    //   const resp = await client.post('/game/start', JSON.stringify({
    //     id: gameId
    //   }))
    //   if (resp.status_code === 'SUCCESS') {
    //     console.log(resp)
    //   } else {
    //   }
    }
  },
  data () {
    return {
      link_to_game: null,
      gameTab: [
        { name: 'รวมสล็อต', page: '/member/games/' },
        { name: 'คาสิโน', page: '/member/games/casino' },
        { name: 'เกมส์ยิงปลา', page: '/member/games/fisher' },
        { name: 'กีฬา', page: '/member/games/sport' }
        // { name: 'VS-Sport', page: '/member/games/vs_sport' }
      ],
      active: '/member/games/fisher'
    }
  }
}
</script>
<style>
.img-cuesor-pointer {
  cursor: pointer;
}
.lightSweep {
  /* position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgba(0, 0, 0, 0); */
  overflow: hidden;
  /* -webkit-filter: brightness(1.5); */
  -webkit-mask-size: 300% 200%;
  -webkit-mask-image: -webkit-linear-gradient(
    -45deg,
    rgb(193 193 193) 47%,
    rgb(193 193 193 / 75%) 50%,
    rgb(181 180 180) 53%
  );
  -webkit-transition: -webkit-mask-position;
  -webkit-animation-timing-function: linear;
  -webkit-transform: translate3d(0, 0, 0);
  /* -moz-filter: brightness(1.5); */
  -moz-mask-size: 300% 200%;
  -moz-mask-image: -webkit-linear-gradient(
    -45deg,
    rgb(193 193 193) 47%,
    rgb(193 193 193 / 75%) 50%,
    rgb(181 180 180) 53%
  );
  -moz-transition: -webkit-mask-position;
  -moz-animation-timing-function: linear;
  -moz-transform: translate3d(0, 0, 0);
  /* -o-filter: brightness(1.5); */
  -o-mask-size: 300% 200%;
  -o-mask-image: -webkit-linear-gradient(
    -45deg,
    rgb(193 193 193) 47%,
    rgb(193 193 193 / 75%) 50%,
    rgb(181 180 180) 53%
  );
  -o-transition: -webkit-mask-position;
  -o-animation-timing-function: linear;
  -o-transform: translate3d(0, 0, 0);
  /* filter: brightness(1.5); */
  mask-size: 300% 200%;
  mask-image: -webkit-linear-gradient(
    -45deg,
    rgb(193 193 193) 47%,
    rgb(193 193 193 / 75%) 50%,
    rgb(181 180 180) 53%
  );
  transition: -webkit-mask-position;
  animation-timing-function: linear;
  transform: translate3d(0, 0, 0);
  transition-duration: 2s;
  animation: move 4s ease-out infinite;
}
@keyframes move {
  from {
    -webkit-mask-position: 150% 0px;
    -moz-mask-position: 150% 0px;
    -o-mask-position: 150% 0px;
    mask-position: 150% 0px;
  }
  to {
    -webkit-mask-position: 0% 0px;
    -moz-mask-position: 0% 0px;
    -o-mask-position: 0% 0px;
    mask-position: 0% 0px;
  }
}
.btn-primary {
    background: linear-gradient( 118deg, rgb(26 74 173) 0%, rgb(13 171 254 / 55%) 100%, rgb(0 0 0 / 72%) 100%);
    box-shadow: 0px 0px 5px #055dd3cc;
}
</style>
